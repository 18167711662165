import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ProductConsumer } from "../context";
import { toPng } from "html-to-image";
import BottomInfoSection from "./BottomInfoSection";

const OrderSummary = () => {
  const summaryRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (summaryRef.current) {
      toPng(summaryRef.current)
        .then((dataUrl) => {
          setImageUrl(dataUrl);
        })
        .catch((err) => {
          console.error("Failed to generate image:", err);
        });
    }
  }, [summaryRef]);

  const generateOrderNumber = () => {
    return Math.floor(10000 + Math.random() * 9000);
  };

  const orderNumber = generateOrderNumber();
  const currentTime = new Date().toLocaleString("zh-CN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <ProductConsumer>
      {(value) => {
        const cartItems = value.cart;
        const totalPrice = value.cart.reduce(
          (acc, item) => acc + item.price * item.count,
          0
        );

        return (
          <PageWrapper>
            {imageUrl ? (
              <ImageContainer>
                <img src={imageUrl} alt="訂單圖片" />
                <button
                  style={{ color: "#000" }}
                  onClick={() => history.push("/?tab=購買流程")}
                >
                  返回查看購買流程
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column", // Stack items vertically
                    alignItems: "center", // Center aligns text and QR code horizontally
                  }}
                >
                  <img
                    src={"img/QR-code.jpg"}
                    alt="WeChat QR Code"
                    style={{
                      width: "8rem", // Relative size for QR code
                      marginBottom: "0.1rem", // Space between QR code and text
                      marginTop: "1rem"
                    }}
                  />
                  <span
                    style={{
                      fontSize: "0.6rem", // Font size relative to the root
                      color: "#333",
                      fontWeight: "bold",
                      textAlign: "center", // Center aligns text below the QR code
                    }}
                  >
                    微信客服
                  </span>
                </div>
              </ImageContainer>
            ) : (
              <SummaryContainer ref={summaryRef}>
                <h1 style={{ fontSize: "0.9rem" }}>訂單詳情</h1>
                <CartDetails>
                  <OrderNumber>訂單號: {orderNumber}</OrderNumber>
                  <OrderTime>下單時間: {currentTime}</OrderTime>
                </CartDetails>
                {cartItems.map((item) => (
                  <CartItem key={item.id}>
                    <span>
                      <strong>{item.title}</strong> x {item.count}
                    </span>
                    <span>
                      <strong>¥{item.price * item.count}</strong>
                    </span>
                  </CartItem>
                ))}
                <TotalPrice>總計: RMB ¥{totalPrice}</TotalPrice>
              </SummaryContainer>
            )}
            <hr
              style={{
                marginTop: "10rem",
                border: "none",
                borderTop: "0.125rem solid #ddd", // Adjusted for consistency
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
            />
            <BottomSectionWrapper>
              <BottomInfoSection />
            </BottomSectionWrapper>
          </PageWrapper>
        );
      }}
    </ProductConsumer>
  );
};

const BottomSectionWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #ddd; /* Add top border */
  padding: 1rem 0;
`;

const OrderTime = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #979393;
`;

const PageWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  button {
    padding: 0.5rem 1rem;
    background-color: #f9d537;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    min-width: 48px;
    min-height: 48px;
    text-align: center;

    &:hover {
      background-color: #d4a200;
    }
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
    h1 {
      font-size: 1.2rem;
    }
  }
`;

const CartDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

const OrderNumber = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #979393;
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;

  span {
    font-size: 0.7rem;
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Ensure content doesn't overflow */
    text-overflow: ellipsis; /* Add ellipsis for long text */
  }

  @media (max-width: 768px) {
    span {
      font-size: 0.7rem;
    }
  }
`;

const TotalPrice = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  button {
    background-color: #b68e5b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: #d4a200;
    }
  }
`;

export default OrderSummary;
