import React from "react";
import styled from "styled-components";

const PurchaseSteps = () => {
  return (
    <StepsWrapper>
      <div className="step">
        <div className="box">選購商品，添加至購物車</div>
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="arrow-icon">
            <path d="M12 22L6 12H10V2H14V12H18L12 22Z" />
          </svg>
        </div>
      </div>
      <div className="step">
        <div className="box">生成電子訂單後截圖或保存</div>
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="arrow-icon">
            <path d="M12 22L6 12H10V2H14V12H18L12 22Z" />
          </svg>
        </div>
      </div>
      <div className="step">
        <div className="box">
          添加並發送至微信客服
          <img src="/img/QR-code.jpg" alt="WeChat QR Code" className="qr-code" />
        </div>
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="arrow-icon">
            <path d="M12 22L6 12H10V2H14V12H18L12 22Z" />
          </svg>
        </div>
      </div>
      <div className="step">
        <div className="box">客服協助您完成付款及交收事宜</div>
        <div style={{ height: "10rem" }}></div>
      </div>
    </StepsWrapper>
  );
};

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: url("/img/purchase-steps-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 10px;
    position: relative;

    .box {
      background-color: #fcca59;
      padding: 15px 30px;
      font-size: 1rem;
      font-weight: bold;
      color: #333;
      border: 2px solid #b68e5b;
      border-radius: 8px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 18rem;
      position: relative;
      
      .qr-code {
        width: 25%;
        height: auto;
        margin-left: 0.2rem;
        border-radius: 8px;
      }
    }

    .arrow {
      margin: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .arrow-icon {
        width: 40px;
        height: 40px;
        fill: #b68e5b;
        transition: fill 0.3s ease;

        &:hover {
          fill: #555;
        }
      }
    }
  }
`;

export default PurchaseSteps;
