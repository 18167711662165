import React from "react";

const BottomInfoSection = () => {

  const cigarBrands = Array.from({ length: 28 }, (_, i) => `/img/brand-${i + 1}.jpg`);
  return (
    <div
      className="info-section"
      style={{
        paddingBottom: "1rem", // Relative padding
      }}
    >
       <img
        src="/img/bottom-info-image.jpeg"
        alt="garturbo cigar brand"
        style={{
          width: "95%",  // Auto scales to fit screen width
          height: "auto", // Maintains aspect ratio
          maxWidth: "1200px", // Optional: Set a max width for larger screens
          display: "block",
          margin: "0 auto", // Centers the image
          paddingTop: "1rem"
        }}
      />

      {/* New Cigar Brands Section */}
      <div
        style={{
          marginTop: "2rem",
          marginLeft: "2rem",
          marginRight: "2rem",
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Wrap items for responsive layout
            justifyContent: "center",
            gap: "0.5rem", // Add spacing between items
          }}
        >
          {cigarBrands.map((brand, index) => (
            <img
              key={index}
              src={brand}
              alt={`Cigar Brand ${index + 1}`}
              style={{
                width: "12%", // Image size
                height: "auto", // Maintain aspect ratio
                borderRadius: "0.5rem",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "scale(1.1)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "scale(1)";
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomInfoSection;
