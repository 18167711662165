import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { AiOutlineShopping, AiOutlineClose } from "react-icons/ai";
import { ProductConsumer } from "../context";
import { useHistory } from "react-router-dom";
import { AgeVerificationContext } from "../context/AgeVerificationContext";

const BottomNavbar = () => {
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const { isVerified } = useContext(AgeVerificationContext);
  const summaryRef = useRef(null);
  const history = useHistory();

  const toggleSummary = () => {
    setIsSummaryVisible(!isSummaryVisible);
  };

  const closeSummary = () => {
    setIsSummaryVisible(false);
  };

  const handleClickOutside = (event) => {
    if (summaryRef.current && !summaryRef.current.contains(event.target)) {
      closeSummary();
    }
  };

  const handleOrder = () => {
    history.push("/order-summary");
  };

  useEffect(() => {
    if (isSummaryVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSummaryVisible]);

  if (!isVerified) {
    return null;
  }

  return (
    <ProductConsumer>
      {(value) => {
        const totalPrice = value.cart.reduce(
          (acc, item) => acc + item.price * item.count,
          0
        );
        const totalItems = value.cart.reduce(
          (acc, item) => acc + item.count,
          0
        );

        return (
          <>
            <CartSummary visible={isSummaryVisible} ref={summaryRef}>
              <SummaryContent>
                <Header>
                  <div className="summary-title">
                    <h2>購物車摘要</h2>
                    {totalItems > 0 && (
                      <ClearButton onClick={value.clearCart}>清空</ClearButton>
                    )}
                  </div>
                  <CloseButton onClick={closeSummary}>
                    <AiOutlineClose size={24} />
                  </CloseButton>
                </Header>

                {/* Total Price and Items Row */}
                <TotalRow>
                  <span>總計: RMB ¥{totalPrice}</span>
                  <span>商品總數: {totalItems}</span>
                </TotalRow>

                {value.cart.length > 0 ? (
                  value.cart.map((item) => (
                    <CartItem key={item.id}>
                      <div className="item-details">
                        <span className="item-name">{item.title}</span>
                        <span className="item-price">RMB ¥{item.price}</span>
                      </div>
                      <div className="item-controls">
                        <button
                          onClick={() => value.decrement(item.id)}
                          className="control-button decrement"
                          disabled={item.count === 0} // Disable decrement when count is 0
                        >
                          −
                        </button>
                        <span className="item-count">{item.count}</span>
                        <button
                          onClick={() => value.increment(item.id)}
                          className="control-button increment"
                          disabled={item.count >= item.stock} // Disable increment when count reaches stock
                        >
                          +
                        </button>
                      </div>
                    </CartItem>
                  ))
                ) : (
                  <p>您的購物車是空的</p>
                )}
              </SummaryContent>
            </CartSummary>

            <BottomNavWrapper>
              <div className="left" onClick={toggleSummary}>
                <AiOutlineShopping size={24} className="cart-icon" />
                <span className="total-price">RMB ¥{totalPrice}</span>
              </div>
              <button
                disabled={value.cart.length === 0}
                onClick={handleOrder}
                className="order-button"
              >
                生成訂單
              </button>
            </BottomNavWrapper>
          </>
        );
      }}
    </ProductConsumer>
  );
};

const ClearButton = styled.button`
  background-color: #6b6b6b; /* Grey background */
  color: #ffffff; /* White text */
  border: none;
  cursor: pointer;
  font-size: 0.7rem;
  padding: 0.3rem 0.6rem; /* Add padding for better appearance */
  border-radius: 8px; /* Rounded corners */
  margin-right: 1rem;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #555555; /* Darker grey on hover */
  }
`;

const BottomNavWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  .left {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .cart-icon {
    color: #b68e5b;
    margin-right: 0.5rem;
  }

  .total-price {
    font-size: 1rem;
    font-weight: bold;
    color: #6b6b6b;
  }

  .order-button {
    background-color: #b68e5b;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #d4a200;
    }
    &:disabled {
      background-color: #ddd;
      color: #888;
      cursor: not-allowed;
    }
  }
`;

const CartSummary = styled.div`
  position: fixed;
  bottom: ${(props) => (props.visible ? "0" : "-100%")};
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease;
  z-index: 1100;
  max-height: 50vh;
  overflow-y: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const SummaryContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  p {
    text-align: center;
    font-size: 1rem;
    color: #6b6b6b;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between; /* Space between the left (title + button) and right (close button) */
  align-items: center; /* Vertically align all elements */

  .summary-title {
    display: flex;
    align-items: center; /* Vertically align the text and button */
    gap: 0.5rem; /* Space between "購物車摘要" and "清空" */
  }

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  padding: 0.5rem 0;
  border-top: 1px solid #ddd;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease;

  &:hover {
    color: #d4a200;
  }
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;

  .item-details {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .item-name {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }

  .item-price {
    font-size: 0.9rem;
    color: #6b6b6b;
  }

  .item-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .control-button {
    width: 20px; /* Smaller size */
    height: 20px;
    border-radius: 50%;
    font-size: 1rem; /* Smaller font size */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    &.decrement {
      background-color: transparent;
      border: 1px solid #b68e5b; /* Thinner border */
      color: #b68e5b;
    }

    &.increment {
      background-color: #b68e5b;
      border: none;
      padding-bottom: 2px;
      color: #fff;
    }
  }

  .control-button.increment:disabled {
    background-color: #e0e0e0; /* Gray background for disabled state */
    color: #a0a0a0; /* Light gray text color */
    cursor: not-allowed; /* Show a not-allowed cursor */
    border: 1px solid #d0d0d0; /* Light gray border */
  }

  .item-count {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    width: 0.8rem; /* Fixed width ensures alignment across digits */
  }
`;

export default BottomNavbar;
